import React from "react";
import './footer.scss';
import facebook from "../../images/icons/social/facebook.svg";
import instagram from "../../images/icons/social/instagram.svg";
import linkedin from "../../images/icons/social/linkedin.svg";
import twitter from "../../images/icons/social/twitter.svg";
import youtube from "../../images/icons/social/youtube.svg";

export default function Footer() {
    const year = new Date().getFullYear();
    return (
        <section className={'footer'}>
            <div className="column follow-us">
                <h4>Seguici su</h4>
                <nav className={'social-navigation'}>
                    <ol>
                        <li><a href="https://www.facebook.com/FairtradeItalia" className={'social-icon facebook'}
                               target="_blank" rel="noopener">
                            <img src={facebook} alt="Facebook"/>
                        </a></li>
                        <li><a href="https://twitter.com/FairtradeItalia" className={'social-icon twitter'}
                               target="_blank" rel="noopener">
                            <img src={twitter} alt="Twitter"/>
                        </a></li>
                        <li><a href="https://www.youtube.com/user/fairtradeitalia" className={'social-icon youtube'}
                               target="_blank" rel="noopener">
                            <img src={youtube} alt="Youtube"/>
                        </a></li>
                        <li><a href="https://www.linkedin.com/company/fairtrade-italia"
                               className={'social-icon linkedin'}
                               target="_blank" rel="noopener">
                            <img src={linkedin} alt="Linkedin"/>
                        </a></li>
                        <li><a href="https://www.instagram.com/fairtrade.it/" className={'social-icon instagram'}
                               target="_blank" rel="noopener">
                            <img src={instagram} alt="Instagram"/>
                        </a></li>
                    </ol>
                </nav>
            </div>
            <div className={'footer-links-container'}>
                <nav className={'privacy-navigation inner-navigation'}>
                    <ol>
                        <li><a href="https://www.fairtrade.net/it-it/privacy-cookies-policy.html" target="_blank">Privacy policy</a>
                        </li>
                        <li><a href="https://www.fairtrade.net/it-it/termini-e-condizioni.html" target="_blank">Termini e
                            condizioni</a>
                        </li>
                        <li><a href="https://www.fairtrade.net/it-it/newsletter.html" target="_blank">Iscriviti alla
                            newsletter</a></li>
                    </ol>
                </nav>

                <div className={'copyright-area'}>
                    <span className={'copyright'}>© Fairtrade Italia {year}</span>
                    <span className={'vat-number'}>Partita IVA: 02649970239</span>
                </div>
            </div>
        </section>
    )
}